<template>
  <div>
    <section>
        <article class="contact_body pb-5">
          <div class="container">
            <div class="row">
              <div class="col-md-2"></div>
              <div class="col-md-8">
                <h2
                  class="text-center"
                  style="font-size: 1.5em; font-weight: 600"
                >
                  If you'd like to plan a stay at Acaki Lodge you can
                  book one of our deluxe lodges using our online booking tool,or
                  if you'd like to speak to our team we're just a call away or
                  send us an email.
                </h2>
                <div
                  class="w-100 d-flex align-items-center justify-content-center"
                >
                  <div style="width: 200px">
                    <el-divider><i class="el-icon-sunny"></i></el-divider>
                  </div>
                </div>
              </div>
              <div class="col-md-2"></div>
            </div>

            <section>
              <article>
                <div class="help_grid">
                  <div>
                    <el-card
                      shadow="hover"
                      class="h-100 d-flex align-items-center justify-content-center"
                    >
                      <div class="text-center contact-content">
                        <h3 class="m-0 p-0">Address</h3>
                        <span>
                          Plot 231-241 Awich Road,Alango West Ward,Pandwong Division Kitgum Municipality, Uganda
                        </span>
                      </div>
                    </el-card>
                  </div>
                  <div>
                    <el-card
                      shadow="hover"
                      class="h-100 d-flex align-items-center justify-content-center"
                    >
                      <div class="text-center contact-content">
                        <h3 class="m-0 p-0">Phone Number</h3>
                        <span>+256 393 242 288, </span>
                        <span>+256 783 377 001, </span>
                        <span>+256 707 377 000</span>
                      </div>
                    </el-card>
                  </div>
                  <div>
                    <el-card
                      shadow="hover"
                      class="h-100 d-flex align-items-center justify-content-center"
                    >
                      <div class="text-center contact-content">
                        <h3 class="m-0 p-0">Email Address</h3>
                        <span>info@acakilodge.co.ug</span>
                      </div>
                    </el-card>
                  </div>
                </div>
              </article>
            </section>

            <section>
              <article>
                <div class="contact_form">
                  <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-8">
                      <el-form
                        :model="contactForm"
                        :rules="rules"
                        ref="contactForm"
                        label-width="120px"
                        class="demo-contactForm"
                        label-position="top"
                      >
                        <div class="row">
                          <div class="col-md-6">
                            <el-form-item label="First Name" prop="firstName">
                              <el-input
                                v-model="contactForm.firstName"
                                placeholder="First Name"
                              ></el-input>
                            </el-form-item>
                          </div>
                          <div class="col-md-6">
                            <el-form-item label="Last Name" prop="lastName">
                              <el-input
                                v-model="contactForm.lastName"
                                placeholder="Last Name"
                              ></el-input>
                            </el-form-item>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <el-form-item label="Email" prop="email">
                              <el-input
                                v-model="contactForm.email"
                                placeholder="Email"
                              ></el-input>
                            </el-form-item>
                          </div>
                          <div class="col-md-6">
                            <el-form-item label="Phone Number" prop="phone">
                              <el-input
                                v-model="contactForm.phone"
                                placeholder="Phone"
                              ></el-input>
                            </el-form-item>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12">
                            <el-form-item label="Message" prop="message">
                              <el-input
                                type="textarea"
                                v-model="contactForm.message"
                                placeholder="Message..."
                              ></el-input>
                            </el-form-item>
                          </div>
                        </div>
                        <div class="text-center">
                          <el-form-item class="m-0">
                            <el-button
                              class="content-btn"
                              @click="submitForm('contactForm')"
                              >Submit</el-button
                            >
                          </el-form-item>
                        </div>
                      </el-form>
                    </div>
                    <div class="col-md-2"></div>
                  </div>
                </div>
              </article>
            </section>
          </div>
        </article>
      </section>

      <section>
        <article>
          <div class="contact-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d22533.018091671973!2d32.894508063850935!3d3.2724425105558557!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1773fbcb5abe784f%3A0x7b18e0684904b3ca!2sACAKI+LODGE!5e0!3m2!1sen!2sus!4v1563869716465!5m2!1sen!2sus"
			
              width="100%"
              height="100%"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </article>
      </section>

  </div>
</template>

<script>
export default {

  data() {
    return {
      contactForm: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
      },
      rules: {
        firstName: [
          {
            required: true,
            message: 'Please input First Name',
            trigger: 'blur',
          },
        ],
        lastName: [
          {
            required: true,
            message: 'Please input Last Name',
            trigger: 'blur',
          },
        ],
        email: [
          {
            required: true,
            message: 'Please input Email',
            trigger: 'change',
          },
        ],
        phone: [
          {
            required: true,
            message: 'Please input Phone',
            trigger: 'change',
          },
        ],
        message: [
          {
            required: true,
            message: 'Please input Message',
            trigger: 'blur',
          },
        ],
      },
    
    };
  },

  methods: {

    
    async submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.loading = true;
          
              
          try {
            let request = await this.$http.post(
              `contact-us`, {
                name: `${this.contactForm.firstName} ${this.contactForm.lastName}`,
                phone: this.contactForm.phone,
                email: this.contactForm.email,
                message: this.contactForm.message
              }
            );
            if (
              request.data.success &&
              request.data.message == "MESSAGE_CREATED_SUCCESSFULLY"
            ) {
              this.resetForm(formName);
              return this.$notify.success({
                title: 'Success',
                message: 'Your Message has been received successfully'
              });

            } else {
              throw "UNEXPECTED_RESPONSE";
            }
          } catch (error) {
            if (error.message == "Network Error") {
              return this.$notify.error({
                title: 'Connection failed',
                message: 'A network error occurred, please try again.'
              });
            }
            this.$notify.error({
                title: 'Loading Failed',
                message: 'Unable to send this message, Please try again later'
              });
          } finally {
            this.loading = false;
          }
        } else {
          this.$notify.error({
            title: 'Error',
            message: 'Unable to send this message.'
          });
          return false;
        }
      });
    },


    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  * {
    font-family: 'Lato', sans-serif;
  }

  ul {
    list-style: none;
    margin: 0px;
    padding:0px;
    font-weight: 300;
  }

  h3{
    font-size: 1.1em;
    font-weight: 600;
    margin-bottom: 15px;
  }


.article1-bg-color-section {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}

.contact_header {
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8)),
    url('/assets/images/buddoss_14.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 100px;
}

.contact_header h1 {
  font-size: 1.2em;
}

.contact_body {
  background-color: #F6E0BF;
  padding-top: 200px;
}

.contact_body .help_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
}

.contact_body .help_grid > div {
  background-color: #fcf9f5;
  height: 180px;
}

.contact_body .help_grid .contact-content span {
  font-weight: 300;
  font-size: 0.8em;
}

.contact_form {
  background-color: #fcf9f5;
  margin-top: 20px;
  padding: 50px 50px;
}

.contact-map {
  background-color: white;
  height: 100vh;
}

ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
  font-weight: 300;
}

h3 {
  font-size: 1.1em;
  font-weight: 600;
  margin-bottom: 15px;
}

/* box-shadow */
ol.gradient-list > li::before,
ol.gradient-list > li {
  box-shadow: 0.25rem 0.25rem 0.6rem rgba(0, 0, 0, 0.05),
    0 0.5rem 1.125rem rgba(75, 0, 0, 0.05);
}

/*** STYLE ***/
*,
*:before,
*:after {
  box-sizing: border-box;
}

ol.gradient-list {
  counter-reset: gradient-counter;
  list-style: none;
  margin: 1.75rem 0;
  padding-left: 1rem;
}

ol.gradient-list > li {
  background: white;
  border-radius: 0 0.5rem 0.5rem 0.5rem;
  counter-increment: gradient-counter;
  margin-top: 1rem;
  min-height: 3rem;
  padding: 1rem 1rem 1rem 3rem;
  position: relative;
}

ol.gradient-list > li::before,
ol.gradient-list > li::after {
  background: linear-gradient(135deg, #83e4e2 0%, #a2ed56 100%);
  border-radius: 1rem 1rem 0 1rem;
  content: '';
  height: 3rem;
  left: -1rem;
  overflow: hidden;
  position: absolute;
  top: -1rem;
  width: 3rem;
}

ol.gradient-list > li::before {
  align-items: flex-end;
  content: counter(gradient-counter);
  color: #1d1f20;
  display: flex;
  font: 900 1.5em/1 'Montserrat';
  justify-content: flex-end;
  padding: 0.125em 0.25em;
  z-index: 1;
}

.content-btn {
  background-color: transparent;
  color: #3D290A;
  border: 2px solid #3D290A;
  border-radius: 0px;
  font-size: 1.2em;
  margin: 0px !important;
  transition: 0.5s;
  width: 250px;
}

.content-btn:hover {
  background-color: #3D290A;
  color: white !important;
  transition: 0.5s;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .contact_body .help_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .contact_body .help_grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 20px;
  }
}
</style>
