<template>
  <div class="acaki-gallery">
    <section>
      <article class="container mt-5">
        <div>
          <h1 style="font-size: 1.6em; font-weight: 600; text-align: left;">Acaki Lodge Gallery</h1>
          <hr/>
        </div>
        <div class="locus_gallery">
            <div class="demo-image__preview" v-for="(list, index ) in srcList" :key="index">
                <el-image 
                    :src="list" 
                    :preview-src-list="srcList">
                </el-image>
            </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>


export default {

  data() {
    return {
      
        srcList: [
            require("../../assets/images/acaki1.jpg"),
            require("../../assets/images/acaki2.jpg"),
            require("../../assets/images/acaki3.jpg"),
            require("../../assets/images/acaki4.jpg"),
            require("../../assets/images/acaki5.jpg"),
            require("../../assets/images/acaki6.jpg"),
            require("../../assets/images/acaki7.jpg"),
            
            require("../../assets/images/acaki8.jpg"),
            require("../../assets/images/acaki9.jpg"),
            require("../../assets/images/acaki10.jpg"),
            require("../../assets/images/acaki11.jpg"),
            require("../../assets/images/acaki12.jpg"),
            require("../../assets/images/acaki13.jpg"),
            require("../../assets/images/acaki14.jpg"),
            require("../../assets/images/acaki15.jpg"),
            require("../../assets/images/acaki16.jpg"),
            require("../../assets/images/acaki17.jpg"),
            require("../../assets/images/acaki18.jpg"),
            require("../../assets/images/acaki19.jpg"),
            require("../../assets/images/acaki20.jpg"),
        ],

    };
  },

  methods: {
  
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.acaki-gallery {
  padding-top: 200px;
}

.locus_gallery {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 100px;
}

.demo-image__preview {
  background-color: #123b2f;
  height: 200px;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .locus_gallery {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 100px;
  }

  .demo-image__preview {
    background-color: #3d290a;
    height: 100px;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .locus_gallery {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
    margin-bottom: 100px;
  }

  .demo-image__preview {
    background-color: #3d290a;
    height: 100px;
  }
}
</style>

<style>
.locus_gallery .demo-image__preview .el-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.locus_gallery .demo-image__preview .el-image img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .locus_gallery .demo-image__preview .el-image {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }

  .locus_gallery .demo-image__preview .el-image img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .locus_gallery .demo-image__preview .el-image {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }

  .locus_gallery .demo-image__preview .el-image img {
    width: 100%;
    height: 100px;
    object-fit: cover;
  }
}
</style>
