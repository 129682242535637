<template>
  <div style="background-color: #f6e0bf;">
   <section>
      <article
        style="
          padding-top: 160px;
          border: 1px solid transparent;
          background-color: #f6e0bf;
          "
      >
      
        <div class="container" style="margin: 50px auto; text-align: left">
          <div class="dining_introduction">
            <p class="locus_word_style">
                    <small>Acaki Lodge  UGANDA</small>
                  </p>
            <h2 style="font-weight: 600; font-size: 2em">
              Stylish Accommodation in Uganda.
            </h2>
            <p style="font-weight: 600; font-size: 1.2em">
              Elegant style and contemporary comfort
            </p>
            <p style="font-weight: 300">
              Acaki Lodge boasts an array of assorted accommodations 
              offering the discerning traveller a variety of choice accommodation 
              to suit their lifestyle, comfort and needs. Whether you travel alone or as a group.
            </p>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article class="rooms">
        <div class="container">
          <!-- {{ categories }} -->
          <div v-if="categories.length === 0 && isLoading" class="px-0 mt-3 col-12">
            <div class="products_skeletons">
              <div
                v-for="i in 20"
                :key="i"
                class="skeleton-block skeleton-effect-wave"
              ></div>
            </div>
          </div>

          <div v-else>
            <section>
              <div class="rooms_grid" v-for="category in categories" :key="category.categoryID">
                <div class="rooms_image">
                  <el-carousel height="260px">
                    <el-carousel-item v-for="image in category.slideRoomImages" :key="image.path">
                      <img :src="image.path" class="d-block w-100" alt="..."/>
                    </el-carousel-item>
                  </el-carousel>
                </div>

                <div class="">
                  <div>
                    <h2 style="font-size: 1.2em; font-weight: 600">{{ category.categoryName }}</h2>
                    <p style="color: grey;">
                      <small>
                        {{category.description }}
                      </small>
                    </p>
                    <div style="display: flex; justify-content: space-between">
                      <div>
                        <h3 style="font-size: 1em; font-weight: 600">SERVICES</h3>
                        <ul style="color: grey;">
                          <li>
                            <small>
                              <i class="fas fa-shower" style="color: #d96b12; padding-right:5px"></i>
                              Amenities in the bathroom
                            </small>
                          </li>
                          <li>
                            <small>
                              <i class="fas fa-wifi" style="color: #d96b12; padding-right:5px"></i>Free Wi-Fi
                              internet
                            </small>
                          </li>
                          <li>
                            <small>
                              <i class="fas fa-tv" style="color: #d96b12; padding-right:5px"></i>
                              Smart TV
                            </small>
                          </li>
                          <li>
                            <small>
                              <i class="el-icon-phone-outline" style="color: #d96b12; padding-right:5px"></i>
                              Room service
                            </small>
                          </li>
                        </ul>
                      </div>
                      <div
                        style="
                          display: flex;
                          justify-content: space-between;
                          flex-direction: column;
                        "
                      >
                        <p></p>
                        <button class="btn btn-brown btn-sm" @click="accommodationDetail(category.categoryID)">
                          BOOK NOW <span class="el-icon-d-arrow-right"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <!-- <section>
            <div class="rooms_grid" v-for="room in rooms" :key="room.id">
              <div class="rooms_image">
                <el-carousel height="260px">
                  <el-carousel-item v-for="image in room.images" :key="image">
                    <img :src="image" class="d-block w-100" alt="..."/>
                  </el-carousel-item>
                </el-carousel>
              </div>

              <div class="">
                <div>
                  <h2 style="font-size: 1.2em; font-weight: 600">{{ room.title }}</h2>
                  <p style="color: grey;">
                    <small>
                      {{ room.description }}
                    </small>
                  </p>
                  <div style="display: flex; justify-content: space-between">
                    <div>
                      <h3 style="font-size: 1em; font-weight: 600">SERVICES</h3>
                      <ul style="color: grey;">
                        <li>
                          <small>
                            <i class="fas fa-shower" style="color: #d96b12; padding-right:5px"></i>
                            Amenities in the bathroom
                          </small>
                        </li>
                        <li>
                          <small>
                            <i class="fas fa-wifi" style="color: #d96b12; padding-right:5px"></i>Free Wi-Fi
                            internet
                          </small>
                        </li>
                        <li>
                          <small>
                            <i class="fas fa-tv" style="color: #d96b12; padding-right:5px"></i>
                            Smart TV
                          </small>
                        </li>
                        <li>
                          <small>
                            <i class="el-icon-phone-outline" style="color: #d96b12; padding-right:5px"></i>
                            Room service
                          </small>
                        </li>
                      </ul>
                    </div>
                    <div
                      style="
                        display: flex;
                        justify-content: space-between;
                        flex-direction: column;
                      "
                    >
                      <p></p>
                      <button class="btn btn-brown btn-sm" @click="accommodationDetail(room.id)">
                        BOOK NOW <span class="el-icon-d-arrow-right"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section> -->
        </div>
      </article>
    </section>
  </div>
</template>

<script>

export default {

  data() {
    return {
      loading: false,
      isLoading: false,
      categories: [],


      table_drawer: false,
      direction: "rtl",

      // rooms: [
      //   {
      //     id: "1",
      //     room_code: "Kookaburra",
      //     title: "Cottages",
      //     totalAmount: 250,
      //     description:
      //       "Ideal for Families or long staying guests, the Cottages features three bed rooms en-suite, a cosy lounge, separate dining area and a kitchen.",
      //     images: [
      //       require("../../assets/images/bg-2.jpg"),
      //       require("../../assets/images/bg-3.jpg"),
      //       require("../../assets/images/bg-4.jpg"),
      //     ],
      //   },
      //   {
      //     id: "3",
      //     title: "Executive Room",
      //     room_code: "Executive",
      //     totalAmount: 80,
      //     description:
      //       "Executive Rooms offer large king size beds and garden views from the private balconies. Ideal for business stays, this room category features an expansive workspace and a relaxation area with a comfortable couch and coffee table.",
      //     images: [
      //       require("../../assets/images/bg-2.jpg"),
      //       require("../../assets/images/bg-3.jpg"),
      //       require("../../assets/images/bg-4.jpg"),
      //     ],
      //   },
      //   {
      //     id: "2",
      //     room_code: "Deluxe",
      //     title: "Deluxe Room",
      //     totalAmount: 60,
      //     description:
      //       "Spacious Deluxe Rooms are elegantly appointed and feature views of the hotel’s water gardens from both the room and private balcony. Furnished with one king or two twin beds",
      //     images: [
      //       require("../../assets/images/bg-2.jpg"),
      //       require("../../assets/images/bg-3.jpg"),
      //       require("../../assets/images/bg-4.jpg"),
      //     ],
      //   },
      // ],

    };
  },

  mounted() {
    this.getCategories();
  },

  methods: {
    handleClose(done) {
      done();
    },

    verifyPhoneNUmber() {
      this.$router.push({ path: `verify-phone-number` });
    },

    accommodationDetail(categoryID) {
      this.$router.push({ path: `accommodation-detail/${categoryID}` });
    },

    async getCategories() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(
          `categories`
        );
        if (request.data.success && request.data.message == "ROOM_CATEGORIES_FETCHED_SUCCESSFULLY") {
          this.categories = request.data.categories;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        // this.$notify({
        //   title: "Operation Failed",
        //   message: "Unable to fetch categories now, please try again",
        //   type: "error",
        // });
      } finally {
        this.isLoading = false;
      }
    },

  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.locus_word_style {
    font-size: 0.5em; font-weight: 300;
    word-spacing: 8px;
    letter-spacing: 5px;
  }
.dining_introduction {
  width: 60%;
}

.dining_grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 30px;
}

.dining_grid > div .dining_grid_image img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}

.goBtn {
  color: white;
  font-weight: 600;
  transition: 0.5s;
}

.dining_grid > div:hover .goBtn {
  margin-left: 20px;
  transition: 0.5s;
}

  .btn-brown {
    background-color: #d96b12;
    color: white;
    width: 200px;
  }
  .btn-view-more {
    background-color: white;
    border: 1px solid #d96b12;
    width: 250px;
  }

  .rooms {
    background-color: #f6e0bf;
  }
 
  .products_skeletons {
    height: 200px;
    border-radius: 5px;
  }

  .rooms_grid {
    background-color: #fff;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 50px;
    border-radius: 5px;
  }

  .rooms_grid > div {
    text-align: left;
    padding: 20px;
    border-radius: 5px;
  }

  .rooms_grid > div:nth-child(2) {
    display: flex;
    align-items: center;
  }

  .rooms_grid .rooms_image img {
    height: 260px;
    width: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .rooms_grid ul {
    list-style: none;
    padding: 0px;
    margin: 0px;
  }

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {.rooms_grid {
      background-color: #fff;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 50px;
      border-radius: 5px;
    }
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {.rooms_grid {
      background-color: #fff;
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: 50px;
      border-radius: 5px;
    }
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  .dining_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .dining_grid > div .dining_grid_image img {
    height: 200px;
    width: 100%;
  }

  .dining_introduction {
    width: 100%;
  }
  
  .rooms_grid {
      background-color: #fff;
      display: grid;
      grid-template-columns: 1fr;
      margin-bottom: 50px;
      border-radius: 5px;
    }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  .dining_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .dining_grid > div .dining_grid_image img {
    height: 200px;
    width: 100%;
  }

  .dining_introduction {
    width: 100%;
  }
    .rooms_grid {
      background-color: #fff;
      display: grid;
      grid-template-columns: 1fr;
      margin-bottom: 50px;
      border-radius: 5px;
    }
}
</style>
