<template>
  <div>
    <article>
      <CarouselComponent></CarouselComponent>
    </article>

    <section>
      <article>
        <HomeComponent></HomeComponent>
      </article>
    </section>

    <section>
      <article>
        <ReviewComponent></ReviewComponent>
      </article>
    </section>
  </div>
</template>

<script>
import HomeComponent from "../../components/website/home/home-component.vue";
import CarouselComponent from "../../components/website/home/carousel-component.vue";
import ReviewComponent from "../../components/website/home/review-component.vue";

export default {
  components: {
    HomeComponent,
    CarouselComponent,
    ReviewComponent,
  },

  data() {
    return {};
  },

  methods: {
    verifyPhoneNUmber() {
      this.$router.push({ path: `verify-phone-number` });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  * {
    font-family: 'Lato', sans-serif;
  }


  

.the-lodge-grid-section {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.the-lodge-grid-section>div {
  min-height: 100vh;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
  justify-content: center;
}

.the-lodge-grid-section>div:first-child,
.the-lodge-grid-section>div:nth-child(4) {
  padding: 10%;
}

.the-lodge-grid-section>.bg-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.the-lodge-grid-section h2 {
  font-size: 1.8em;
  font-weight: 400 !important;
  color: #95BF3D;
}

.the-lodge-grid-section .heading-two {
  font-size: 2.5em;
  font-weight: 200;
  color: #95BF3D;
}

.the-lodge-grid-section .content-paragraph {
  font-size: 1em;
  font-weight: 300;
  color: black;
}

.the-lodge-grid-section .content-btn {
  background-color: transparent;
  color: #95BF3D;
  border: 2px solid #95BF3D;
  border-radius: 0px;
  font-size: 1.2em;
  margin: 0px !important;
  transition: 0.5s;
  width: 300px;
}

.the-lodge-grid-section .content-btn:hover {
  background-color: #95BF3D;
  color: white !important;
  transition: 0.5s;
}
</style>
