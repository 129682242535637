<template>
  <div class=" text-center">

    <article class="theCarouselArticle">
      
      <div class="mt-5 mb-5">
        <h2 style="font-size: 1.2em; font-weight:600;">CUSTOMER REVIEW</h2>
      </div>
      <el-carousel :interval="4000">

        <el-carousel-item v-for="review in reviews" :key="review.id">
          <div class="carousel_description">
            <div class="image-description">
              <div class="container">
                <div>
                  <div class="review_content">
                <div>
                  <div style="display:flex; align-items: center; justify-content: center;">
                    <div class="customer_image">
                        <img :src="review.image">
                    </div>
                  </div>
                  <div class="text-center">
                    <p style="font-size: 1.2em; font-weight: 600;">{{ review.reviewer_name }}</p>
                  </div>
                  <div class="text-center review_text">
                    <p>
                      {{ review.description }}
                    </p>
                  </div>
                </div>
              </div>
                </div>
              </div>
            </div>
          </div>
        </el-carousel-item>

      </el-carousel>
    </article>
    
    <!-- <article class="theEnginesArticle">
      <section>
        <div>
          <h2 style="font-size: 1.2em; font-weight: 600;">Follow us on</h2>
          <div>
            <button class="btn btn-lg engineBtn">
              <img src="../../assets/svgs/facebook.png" width="95%">
            </button>
            <button class="btn btn-lg engineBtn">
              <img src="../../assets/svgs/Tripadvisor_lockup_horizontal_secondary_registered.svg" width="100%">
            </button>
          </div>
        </div>
      </section>
    </article> -->
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {

      reviews: [
        {
          id: "1",
          reviewer_name: "Kim Gardner from France",
          description: "The bed and everything in the room was very clean and comfortable. The free wifi was an acceptable speed. Housekeeping was very good. The free mini-bar was an enjoyable treat, with candies and all sorts of drinks. Especially the 4 bottles of water when the temperature was 90+F everyday haha",
          image: require("../../../assets/images/download.png")
        },
        {
          id: "2",
          reviewer_name: "Jennifer Lawrence from UK",
          description: "The service provided was outstanding and they certainly went above and beyond to make sure our stay was both enjoyable and a 5 star experience. Thank you so much Xavier Schaeffer and your team. Thank you Jessica and Bonnie for looking after us in the club lounge...our drinks were never empty. Also a special thank you to Harry Chan and Lung Wong in concierge for all your assistance and great tips on navigating our way through",
          image: require("../../../assets/images/download.png")
        },
        {
          id: "3",
          reviewer_name: "Linda Smith from US",
          description: "I booked this hotel based on the reviews of the service and the location. The location was incredible. The room was spacious and very comfortable. The hotel offers a club for guests if they arrive very early. I wish the massage chair was there the day we were using the club though.",
          image: require("../../../assets/images/download.png")
        },
      ],

    };
  },
  methods: {
    
  },
};
</script>

<style scoped>

    .theReviewArticle {
        background-color:#ffffff;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .review_content {
      display: flex;
      align-items: center;
      justify-content: center;

    }

    .customer_image {
      background-color: #F6E1C1;
      width: 100px;
      height: 100px;
      border-radius: 50%;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .customer_image img{
      width: 90px;
      height: 90px;
      border-radius: 50%;
    }

    .theEnginesArticle {
        background-color:#fbf2e1;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .theEnginesArticle h2{
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    .theEnginesArticle .engineBtn {
      background-color: white;
      border-radius: 0px;
    }

    .theEnginesArticle .engineBtn:first-child {
      margin-right: 20px;
      width: 160px;
    }
    
    .theEnginesArticle .engineBtn:nth-child(2) {
      margin-right: 20px;
      width: 200px;
    }

    .review_text {
      font-size: 0.8em;
      width: 60%;
      margin: 0px auto;
    }
    
  /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) {
    
  }

  /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  }

  /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
  @media (min-width: 481px) and (max-width: 767px) {
    
    .checkBtn {
        width: 100%;
    }
   
    .carousel_description .image-description {
        padding-bottom: 55%;
    }
    

    .review_text {
      width: 100%;
      margin: 0px auto;
    }

  }

  /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
  @media (min-width: 320px) and (max-width: 480px) {

    .checkBtn {
        width: 100%;
    }
   
    .carousel_description .image-description {
        padding-bottom: 55%;
    }

    .theEnginesArticle .engineBtn:first-child {
      margin-right: 20px;
      width: 140px;
    }
    
    .theEnginesArticle .engineBtn:nth-child(2) {
      margin-right: 20px;
      width: 180px;
    }

    .review_text {
      width: 100%;
      margin: 0px auto;
    }

  }
</style>

<style>
    .input-class .el-input__inner{
        width: 50px !important; 
    }

    .el-date-editor .el-range-input {
        /* width: 20% !important; */
    }

    .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner {
        width: 100% !important;
    }

        
        
    /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) {
        
    }

    /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    }

    /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
    @media (min-width: 481px) and (max-width: 767px) {

    }

    /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
    @media (min-width: 320px) and (max-width: 480px) {
            
        .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner {
            margin-bottom: 20px;
        }

        .el-input-number.is-controls-right {
            width: 100%;
            margin-bottom: 20px;
        }

    }
</style>