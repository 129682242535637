<template>
  <div class="acaki-dining">
    <!-- <CarouselComponent /> -->
    <section>
      <article>
        <div class="container" style="margin: 50px auto; text-align: left">
          <div class="dining_introduction">
            <h3>Restaurants and Hotel Services</h3>
            <!-- <p style="font-weight: 600; font-size: 1.2em">
              Fine Dining Restaurant in  Kitgum
            </p> -->
            <p style="font-weight: 300">
              Acaki Lodge Kitgum Limited runs two-wings restaurants to
              help provide Hotel Services to its’ clients. Both Local and
              International dishes are provided.
            </p>
            <h4>The Restaurants are;</h4>
            <ol>
              <li>Acaki Restaurant </li>
              <li>Café Alpha Restaurant</li>
            </ol>
            <p>In providing hotel services, the restaurants prepare
              food items to clients as follows;</p>
          </div>
        </div>
      </article>
    </section>

    <section>
      <article style="border: 1px solid transparent; background-color: #f6e0bf;">
        <div class="container" style="margin: 0px auto 50px auto; text-align: left">
          <div class="dining_grid">
            <div class="dining_flex" v-for="dining in dining_features" :key="dining.id">
              <div>
                <div class="dining_grid_image">
                  <img :src=" dining.image " />
                </div>
                <div class="dining_features">
                  <div class="mt-3">
                    <p style="font-weight: 600; font-size: 1.2em">
                      {{ dining.title }}
                    </p>
                    <p style="font-weight: 300">
                      {{ dining.description }}
                    </p>
                  </div>
                </div>
              </div>
                <div>
                  <!-- <BookTableComponent/> -->
                </div>
            </div>
            


          </div>
        </div>
      </article>
    </section>
  </div>
</template>

<script>
  // import CarouselComponent from "../../components/website/dining/carousel-component.vue";
  // import BookTableComponent from '../../components/website/dining/book-table-component.vue'

  export default {
    // components: { CarouselComponent, BookTableComponent },

    data() {
      return {
        table_drawer: false,
        direction: "rtl",

        dining_features: [
          {
            id: "1",
            title: "ALA-CARTE SERVICES",
            description: "From the restaurants, an individual dishes from a menu list shall be ordered by clients. The individual dishes to be ordered may include side dishes, or the side dishes may be offered separately.",
            image: require("../../assets/images/menu-list.jpeg")
          },
          {
            id: "2",
            title: "BUFFET SERVICES",
            description: "Acaki Restaurants provide meals of various dishes in which clients serve themselves from.",
            image: require("../../assets/images/buffet.jpeg")
          },
          {
            id: "3",
            title: "DESSERTS",
            description: "Dessert is always offered to clients after their mmeals.",
            image: require("../../assets/images/desserts.webp")
          },
          // {
          //   id: "4",
          //   title: "Acaki Terrace.",
          //   description: "Relax and 'whiff in' the beautiful Ugandan fresh air filtered by the various tree species covering the   hill as you enjoy a hearty and sumptuous meal or while sipping a signature cocktail as you view the beautiful city of  Kitgum from above or the majestic Lake Victoria. The penguin terrace is ideal as a viewing gallery of the “City of the seven hills",
          //   image: require("../../assets/images/image_2.jpg")
          // },
          {
            id: "5",
            title: "Barista & Mixologist.",
            description: "At Acaki Lodge, our skilled barista and mixologist craft exceptional coffee, refreshing drinks, and innovative cocktails, providing guests with a delightful beverage experience in a relaxing and inviting atmosphere.",
            image: require("../../assets/images/meetings/coffee.jpeg")
          },
          // {
          //   id: "6",
          //   title: "Meet The Chef.",
          //   description: "The man with gifted hands!” Chef Patrick started his culinary journey 15 years ago when he realized his passion for cookery.  He trained under the best Chefs in Leading Hotels and restaurants which include Serena, Le Chateau, Marasa Africa group where he led the Kitchen brigade in several properties before coming in to be the pioneer Chef at Locus Hotel.",
          //   image: require("../../assets/images/image_6.jpg")
          // }
        ]
      };
    },

    methods: {
      handleClose(done) {
        done();
      },

      verifyPhoneNUmber() {
        this.$router.push({ path: `verify-phone-number` });
      },
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.acaki-dining {
  padding-top: 200px;
}
.dining_introduction {
  width: 60%;
}

.dining_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 30px;
}

  .dining_flex {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }

  .dining_flex >div:nth-child(3){
    border-radius: 0px 0px 5px 5px;
  }

.dining_grid > div .dining_grid_image img {
  height: 400px;
  width: 100%;
  object-fit: cover;
}

.goBtn {
  color: white;
  font-weight: 600;
  transition: 0.5s;
}

.dining_grid > div:hover .goBtn {
  margin-left: 20px;
  transition: 0.5s;
}

/*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) {
}

/*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
}

/*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
@media (min-width: 481px) and (max-width: 767px) {
  
  
.acaki-dining {
  padding-top: 100px;
}
.dining_introduction {
  width: 100%;
}

.dining_grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
}

  .dining_grid > div .dining_grid_image img {
    height: 200px;
    width: 100%;
  }
}

/*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
@media (min-width: 320px) and (max-width: 480px) {
  
.acaki-dining {
  padding-top: 100px;
}
.dining_introduction {
  width: 100%;
}

.dining_grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
}

  .dining_grid > div .dining_grid_image img {
    height: 200px;
    width: 100%;
  }
}
</style>
