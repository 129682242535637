<template>
  <div>
    <article class="theCarouselArticle">
      <div class="slider">
        <el-carousel height="90vh" :interval="5000" arrow="always">
          <el-carousel-item>
            <div class="carousel_description">
              <img
                src="../../../assets/images/acaki1.jpg"
                class="d-block w-100"
                alt="..."
              />
              <div class="image-description">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <p class="locus_word_style">
                        <small>Acaki Lodge UGANDA</small>
                      </p>
                      <p>Discover unparalleled comfort in our safari lodge's exquisite cottage accommodations.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
          <el-carousel-item>
            <div class="carousel_description">
              <img
                src="../../../assets/images/acaki19.jpg"
                class="d-block w-100"
                alt="..."
              />
              <div class="image-description">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <p class="locus_word_style">
                        <small>Acaki Lodge UGANDA</small>
                      </p>
                      <p>Experience nature and comfort in our exclusive safari lodge cottages.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
          
          <el-carousel-item>
            <div class="carousel_description">
              <img
                src="../../../assets/images/acaki5.jpg"
                class="d-block w-100"
                alt="..."
              />
              <div class="image-description">
                <div class="container">
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <p class="locus_word_style">
                        <small>Acaki Lodge UGANDA</small>
                      </p>
                      <p>Relax and unwind in our elegantly designed safari lodge cottages.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div class="blog text-center">
        <el-carousel height="90vh" :interval="5000" arrow="always">
          <el-carousel-item v-for="post in posts" :key="post.blogID">
            <div class="blog-post">
              <div class="pl-5 pr-5">
                <h2 class="mb-4">{{ post.blogTitle }}</h2>
                <!-- <p class="mb-4">
                  {{ truncate(post.description, 200) }}
                </p> -->
                <div class="mt-4" v-html="truncate(post.description, 200)"></div>
              </div>
              <div>
                <div>
                  <img
                    :src="post.blogImage"
                    alt="..."
                    width="50%"
                  />
                </div>
                <button class="btn btn-md moreBtn mt-4" @click="$router.push({path: `/blog/${post.blogID}`})">
                  DISCOVER MORE
                  <i class="el-icon-right"></i>
                </button>
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
        <!-- {{ latestPost }} -->
        <!-- <div class="pl-5 pr-5">
          <h2 class="mb-4">{{ latestPost.blogTitle }}</h2>
          <p class="mb-4">
            {{ truncate(latestPost.description, 200) }}
          </p>
        </div>
              <div>
                <div>
                  <img
                    :src="latestPost.blogImage"
                    alt="..."
                    width="50%"
                  />
                </div>
                <button class="btn btn-md moreBtn mt-4" @click="$router.push({path: `/blog/${latestPost.blogID}`})">
                  DISCOVER MORE
                  <i class="el-icon-right"></i>
                </button>
              </div> -->
      </div>
    </article>

    <section>
        <div class="theCheckAvailabilitySection">
          <div>
            <div class="container">
              <div
                style="text-align: left;"
              >
                <div class="availabilityForm">
                  <div class="row">
                    <div class="col-6">
                      <label>Check-In</label><br />
                      <el-date-picker
                        v-model="checkInDate"
                        type="date"
                        placeholder="Check-In"
                        style="width: 100%;">
                      </el-date-picker>
                    </div>
                    <div class="col-6">
                      <label>Check-Out</label><br />
                      <el-date-picker
                        v-model="checkOutDate"
                        type="date"
                        placeholder="Check-Out"
                         style="width: 100%;">
                      </el-date-picker>
                    </div>
                  </div>

                  <div class="row peopleNo">
                    <div class="col-6">
                        <label>Adults</label><br />
                        <el-input-number
                        v-model="numOfAdults"
                        controls-position="right"
                        @change="handleChange"
                        :min="1"
                        :max="10"
                        ></el-input-number>
                    </div>
                    <div class="col-6">
                        <label>Children</label><br />
                        <el-input-number
                          v-model="numOfChildren"
                          controls-position="right"
                          @change="handleChange"
                          :min="1"
                          :max="10"
                        ></el-input-number>
                    </div>
                  </div>

                  <div class="">
                    <el-button 
                      class="checkBtn"
                      @click="$router.push({path: 'accommodation'})"
                      >Check Availability <span class="el-icon-d-arrow-right"></span></el-button
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      isLoading: false,
      isLoadingError: false,
      num: 1,
      checkInDate: '',
      checkOutDate: '',
      numOfAdults: 0,
      numOfChildren: 0,

      myText: 'Discover unparalleled comfort in our safari lodges exquisite cottage accommodation Discover unparalleled comfort in our safari lodges exquisite cottage accommodatio Discover unparalleled comfort in our safari lodges exquisite cottage accommodatio Discover unparalleled comfort in our safari lodges exquisite cottage accommodation...'
      ,
      latestPost: {},
      posts: []
    };
  },
  
  mounted() {
    this.getBlog();
  },

  methods: {
    handleChange(value) {
      console.log(value);
    },


    truncate(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + '...';
      }
      return text;
    },
    
    async getBlog() {
      this.isLoading = true;
      this.isLoadingError = false;
      try {
        //TODO: MAKE THIS REQUEST DYNAMIC AND NOT STATIC
        let request = await this.$http.get(`blog`);
        if (request.data.success && request.data.message === "BLOG_FETCHED_SUCCESSFULLY") {
          this.latestPost = request.data.posts[0];
          this.posts = request.data.posts;
        } else throw "UNEXPECTED_RESPONSE_RECEIVED";
      } catch (error) {
        this.isLoadingError = true;
        //TODO: REPORT THIS TO AN ERROR REPORTING SERVICE
        this.$notify({
          title: "Operation Failed",
          message: "Unable to fetch blog Posts now, please try again",
          type: "error",
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped>
.blog{
  background-color: #eeeeee;
  /* padding: 150px 50px 50px 50px; */
}

.blog-post {
  padding: 150px 50px 50px 50px;
}

.blog h2 {
  font-size: 1.5em;
  font-weight: 600;
}
  .locus_word_style {
    font-size: 0.5em; font-weight: 300;
    word-spacing: 8px;
    letter-spacing: 5px;
  }
.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.carousel_description {
  /* position: relative; */
  height: 90vh;
}
.carousel_description img {
  height: 90vh;
  object-fit: cover;
}

.carousel_description .image-description {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  /* margin-left:10%;
    margin-right:10%; */
  padding-bottom: 10%;
  background-image: linear-gradient(
    to bottom left,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.2)
  );
  display: flex;
  align-items: flex-end;
}

.carousel_description .image-description > div {
  /* background-color: green; */
  text-transform: uppercase;
  font-size: 1.5em;
  font-weight: 600;
  color: white;
  text-align: left;
}

.moreBtn {
  background-color: transparent;
  color: #3D290A;
  border: 1px solid #3D290A;
  border-radius: 0px;
}

.moreBtn:hover {
  background-color: rgba(61, 41, 10, 0.3);
  color: #fbf2e1;
  border: 1px solid #fbf2e1;
  border-radius: 0px;
}

.theCarouselArticle {
  height: 90vh;
  background-color: #f6e0bf;
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* position: relative; */
}

.theCheckAvailabilitySection {
  background-color: #f4f4f4;
  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.theCheckAvailabilitySection >div {
 width: 90%; 
 background-color: rgba(61, 41, 10, 0.8);
 color: #fbf2e1;
 text-transform: uppercase;
 padding-top: 20px;
 padding-bottom: 20px;
}

.availabilityForm {
 display: flex;
 align-items: flex-end;
 justify-content: space-between;

}

.availabilityForm >div{
    font-size: 0.8em;
}

.checkBtn {
    background-color: #D96B12;
    border: 2px solid #D96B12;
    color: #fbf2e1;
    border-radius: 0px;
 text-transform: uppercase;
}


    
  /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) {
    
  }

  /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

  }

  /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
  @media (min-width: 481px) and (max-width: 767px) {
     
    .theCarouselArticle {
      display: grid;
      grid-template-columns: 1fr;
    }
    .blog{
      display: none;
    }
    .availabilityForm {
        display: block;
    }

    .checkBtn {
        width: 100%;
    }

    .theCheckAvailabilitySection {
      
    }
   
    .carousel_description .image-description {
        padding-bottom: 55%;
    }

    .peopleNo {
      margin-top: 20px;
    }
  }

  /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
  @media (min-width: 320px) and (max-width: 480px) {
        
    .theCarouselArticle {
      display: grid;
      grid-template-columns: 1fr;
    }
    .blog{
      display: none;
    }

    .availabilityForm {
        display: block;
    }

    .checkBtn {
        width: 100%;
    }

    .theCheckAvailabilitySection {
    }
   
    .carousel_description .image-description {
        padding-bottom: 55%;
    }

    .peopleNo {
      margin-top: 20px;
    }

  }
</style>

<style>
    .input-class .el-input__inner{
        width: 50px !important; 
    }

    .el-date-editor .el-range-input {
        /* width: 20% !important; */
    }

    .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner {
        width: 100% !important;
    }

        
        
    /*##Device = Tablets, Ipads (portrait)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) {
        
    }

    /*##Device = Tablets, Ipads (landscape)##Screen = B/w 768px to 1024px*/
    @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {

    }

    /*##Device = Low Resolution Tablets, Mobiles (Landscape) ##Screen = B/w 481px to 767px*/
    @media (min-width: 481px) and (max-width: 767px) {

    }

    /*##Device = Most of the Smartphones Mobiles (Portrait) ##Screen = B/w 320px to 479px*/
    @media (min-width: 320px) and (max-width: 480px) {
            
        .el-date-editor--datetimerange.el-input, .el-date-editor--datetimerange.el-input__inner {
            margin-bottom: 20px;
        }

        .el-input-number.is-controls-right {
            width: 100%;
            margin-bottom: 20px;
        }

    }
</style>
